import moment from 'moment';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggle'];
  connect() {
    this.hidePastEvents();
  }
  hidePastEvents() {
    let now = moment().valueOf();
    let eventDateString = this.element.dataset.datetime;
    let eventMoment = moment(eventDateString, 'YYYY-MM-DD HH:mm:ss Z');
    let eventDateTime = eventMoment.valueOf();
    if (eventDateTime < now) {
      this.element.remove();
    }
    this.dispatchCompletedEvent();
  }
  dispatchCompletedEvent() {
    const event = new CustomEvent('screeningCardProcessed', { bubbles: true });
    this.element.dispatchEvent(event);
  }
}
